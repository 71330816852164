import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import Search from "../components/search"
import styles from "./list.module.scss"


class ListTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const siteTitle = translate('site.name', lang)
    const { worlds, notations } = this.props.pageContext
    const headerSubTitle = translate('site.description', lang)

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={styles.search}>
            {/* <input type="text" className={styles.input}></input>
            <button type="button">{translate('list.search', lang)}</button> */}
            <Search lang={lang}></Search>
          </div>
          <div className={`${styles.list} columns is-desktop is-multiline`}>
            {
              worlds.map(
                (w, i) => {
                  const worldName = w.fields.slug.split('/')[3]
                  return <div key={i} className={`${styles.world} column is-one-fifth`}>
                    <h3 className={styles.title}>
                      <Link to={w.fields.slug}
                        style={{
                          boxShadow: `none`,
                          textDecoration: `none`,
                          color: `inherit`,
                        }}
                      >{w.frontmatter.title}</Link>
                    </h3>
                    <dl className={styles.notations}>
                      {
                        notations.filter(
                          n => n.fields.slug.split('/')[3] === worldName
                        ).map(
                          (n, j) => {
                            return <dt key={j} className={styles.notation}>
                              <Link to={n.fields.slug}
                                style={{
                                  boxShadow: `none`,
                                  textDecoration: `none`,
                                  color: `inherit`,
                                }}
                              >{n.frontmatter.title}</Link>
                            </dt>
                          }
                        )
                      }
                    </dl>
                  </div>
                }
              )
            }
          </div>
        </article>
      </Layout>
    )
  }
}

export default ListTemplate

export const pageQuery = graphql`
  query ListBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
