import React from 'react'
import { Link } from 'gatsby'
import { Highlight } from 'react-instantsearch-dom'
import typography, { scale } from '../utils/typography'

export function SearchHit({ hit }) {
  const { id: slug, publishedAt, timeToRead, tags } = hit
  const dateFormatter = new Intl.DateTimeFormat()

  return (
    <div className="OnSiteSearchHit__root">
      <Link to={`${slug}`} style={{ color: typography.options.bodyColor }}>
        <div className="OnSiteSearchHit__headline">
          <strong
            style={{
              ...scale(),
            }}
          >
            <Highlight hit={hit} attribute="title" />
          </strong>
        </div>
        <span
          style={{
            ...scale(-0.1),
          }}
          className="OnSiteSearchHit__description"
        >
          <Highlight hit={hit} attribute="excerpt" />
        </span>
      </Link>
    </div>
  )
}

export default SearchHit
