import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom'
import { SearchHitList } from './search-hit-list'
import translate from '../utils/translation'

const searchClient = algoliasearch(
  '0AQOUIGSY5',
  'b9157277dcc9f6361f1b48bed2cc44c5'
)

class Search extends React.Component {
  
  render() {
    const placeholder = translate('list.search', this.props.lang)
    return (
      <InstantSearch
        indexName='butoh-kaden'
        searchClient={searchClient}
      >
        <Configure
          // https://www.algolia.com/doc/api-reference/search-api-parameters/
          hitsPerPage={20}
          removeStopWords
          analytics
          analyticsTags={['on-site-search']}
          filters={`tags:${this.props.lang}`}
        />
        <SearchBox showLoadingIndicator submit={null} reset={null}
        translations={
          {
            submitTitle: '',
            resetTitle: '',
            placeholder: placeholder
          }
        }
        />
        <SearchHitList />
      </InstantSearch>
    )
  }

}

export default Search
